
import { defineComponent, onMounted, ref } from "vue";
import { Mutations } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { getManager } from "@/store/modules/AuthModule";

export default defineComponent({
  name: "sign-in",
  components: {},
  setup() {
    const store = useStore();
    onMounted(async () => {
      callAuth();
    });

    function callAuth() {
      let manager = getManager();
      store.commit(Mutations.SET_USER_MANAGER, manager);
      manager.signinRedirect();
    }
    //Form submit function
    return { callAuth };
  },
});
